export default function moderationMailTemplates(participantEmail = '') {
  return {
    selectedTemplate: null,

    handleTemplateSelected(evt) {
      const selectedTemplateId = evt.target.value;
      this.selectedTemplate = document.querySelector(`[data-template-id='${selectedTemplateId}']`);

      this.setEditorContent();
      this.setRecipients();
      this.setSubject();
    },

    setEditorContent() {
      const content = this.selectedTemplate?.innerHTML || '';
      this.$refs.editor && tinymce.get(this.$refs.editor.id).setContent(content);
    },

    setRecipients() {
      const recipients = this.selectedTemplate?.dataset?.recipients ?? participantEmail;
      this.$refs.recipients.value = recipients;
    },

    setSubject() {
      const subject = this.selectedTemplate?.dataset?.subject || '';
      this.$refs.subject.value = subject;
    },
  };
}
